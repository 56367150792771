import { ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useHandleLogout from '../../reactWrapper/hooks/useHandleLogout';


export const SideBar = () => {
  const { handleLogout } = useHandleLogout()
  const [showTalentSubmenu, setShowTalentSubmenu] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTalentSubmenu = () => {
    setShowTalentSubmenu((value) => !value);
  };

  return (
    <aside className='sidebar w-[200px] md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-gray-900'>
      <div className='sidebar-header flex items-center justify-center py-4'>
        <div className='inline-flex'>
          <a href='/' className='inline-flex flex-row items-center'>
            <span className='leading-10 text-gray-100 text-2xl font-poppins font-bold ml-1 uppercase'>
              Droomwork
            </span>
          </a>
        </div>
      </div>
      <div className='sidebar-content px-4 py-6'>
        <ul className='flex flex-col w-full'>
          <li className='my-px'>
            <a
              href='/'
              className='flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700'
            >
              <span className='flex items-center justify-center text-lg text-gray-400'>
                <svg
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-5 w-5 text-gray-100'
                >
                  <path d='M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4' />
                </svg>
              </span>
              <Link to='/'>
                <span className='ml-3'>Dashboard</span>
              </Link>
            </a>
          </li>


          <li className='my-px cursor-pointer' onClick={() => setOpen((value) => !value)}>
            <p className='flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700'>
              <span className='flex items-center justify-center text-lg text-gray-400'>
                <svg
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-5 w-5 text-gray-100'
                >
                  <path d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z' />
                </svg>
              </span>
              <span className='ml-3'>Blog</span>
              <ChevronDownIcon className='w-4 h-6 text-gray-100' />
            </p>

            <div
              className={
                open
                  ? 'z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700'
                  : 'hidden z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700'
              }
            >
              <ul
                class='py-1 text-sm text-gray-700 dark:text-gray-200'
                aria-labelledby='dropdownDefault'
              >
                <li>
                  <Link to='/createauthorcategory'>
                    <p class='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                      Create Cat/Aut
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to='/postblog'>
                    <p class='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                      Create Post
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to='/mypost'>
                    <p class='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                      Blog Content
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className='my-px'>
            <a
              href='/search-talent'
              className='flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700'
            >
              <span className='flex items-center justify-center text-lg text-gray-400'>
                <svg
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-5 w-5 text-gray-100'
                >
                  <path d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z' />
                </svg>
              </span>
              <Link to='/search-talent'>
                <span className='ml-3'>Result</span>
              </Link>

            </a>
          </li>

          <li className='my-px cursor-pointer' onClick={handleTalentSubmenu}>
            <p className='flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700'>
              <span className='flex items-center justify-center text-lg text-gray-400'>
                <svg
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-5 w-5 text-gray-100'
                >
                  <path d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z' />
                </svg>
              </span>
              <span className='ml-3'>Talent</span>
              <ChevronDownIcon className='w-4 h-6 text-gray-100' />
            </p>

            <div
              className={
                showTalentSubmenu
                  ? 'z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700'
                  : 'hidden z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700'
              }
            >
              <ul
                class='py-1 text-sm text-gray-700 dark:text-gray-200'
                aria-labelledby='dropdownDefault'
              >
                <li>
                  <Link to='/client-request'>
                    <p class='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                      Client Request
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to='/payments'>
                    <p class='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'>
                      Talent Payment
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className='my-px'>
            <span className='flex font-medium font-poppins  text-sm text-gray-300 px-4 my-4 uppercase'>
              Account
            </span>
          </li>

          <li className='my-px' onClick={handleLogout}>
            <a
              href='/'
              className='flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700'
            >
              <span className='flex items-center justify-center text-lg text-red-400'>
                <svg
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-5 w-5 text-gray-100 '
                >
                  <path d='M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z' />
                </svg>
              </span>
              <span className='ml-3'>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};
