import { AxiosError } from "axios";

import useSWRMutation from "swr/mutation";
import axiosInstance from "../axios";

export const useUserProfileMutation = () => {
  const { data, trigger, isMutating, error } = useSWRMutation<
    { user: any; accessToken: string },
    AxiosError,
    "/all/getUserProfile",
    FormData,
    any
  >("/all/getUserProfile", (url: string, { arg }) =>
    axiosInstance.post(url, arg).then((res) => ({
      accessToken: arg.get("userToken") as string,
      user: res.data,
    }))
  );

  return {
    profile: data,
    isLoading: isMutating,
    getProfile: trigger,
  };
};
