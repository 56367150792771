import React,{ useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useAddNewCategoryMutation } from '../../reactWrapper/Redux/apiSlice';
import CatList from './catList';
import CreateAuthors from './createAuthor'
import AuthorList from './authorList'



const CreateCatAut = () => {
    
    // const [addNewCategory, data, { isLoading }] = useAddNewCategoryMutation();
    const [addNewCategory, data] = useAddNewCategoryMutation();
    console.log('category data', data)

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);

    const [name, setName] = useState('');
    const [userId, setUserId] = useState(uid);

    useEffect(() => {
        console.log('data', data)
      }, [data])
    
    // const canSave = [name, uid].every(Boolean) && !isLoading

    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     if (canSave) {
    //         try {
    //           await addNewCategory({ name,  userD: userId }).unwrap()
    //           setName('')
    //         } catch (err) {
    //           console.error('Failed to save the post: ', err)
    //         }
    //       }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await addNewCategory({ name,  userD: userId })
        setName('')
    }


  return (
    <>
        
 
        <div>

        <div className="font-medium text-base text-black block mb-3">
            Create Category
        </div>

            {/* <div className="flex flex-wrap -mx-4 mb-4"> */}
            <div className="flex flex-col flex-wrap mb-6">
        
                <div className="w-full px-4">
                    <form onSubmit={handleSubmit} className="flex flex-row mb-8">
                        <div className=" basis-3/4 ">
                            
                            <input type="text" placeholder="Active Input" 
                            value={name}
                            onChange={(e)=> setName(e.target.value)}
                            className="
                                w-full
                                border-[1.5px] border-primary
                                rounded-lg
                                py-3
                                px-5
                                font-medium
                                text-body-color
                                placeholder-body-color
                                outline-none
                                focus:border-primary
                                active:border-primary
                                transition
                                disabled:bg-[#F5F7FD] disabled:cursor-default
                                " />
                        </div>

                        <div className="basis-1/4 self-center ml-3.5">
                            <button onClick={handleSubmit} className="place-self-center shadow-lg shadow-blue-500/50 bg-blue-800 hover:bg-blue-900 p-2.5 rounded-lg text-white font-bold transition ease-in duration-200">
                                Create
                            </button> 
                        </div>

                        

                    </form>
                </div>
                                
                <div >
                    <CatList />
                </div>

            </div>

            <div className="flex flex-col flex-wrap mb-6">

                
                    <CreateAuthors />
                

                
                    <AuthorList />
                

            </div>

        </div>
                 
    </>
  )
}

export default CreateCatAut