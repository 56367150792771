import React from 'react';
import { MdLocationOn } from 'react-icons/md';
// import ClientDetails from '../../components/ProjectInfo/ClientDetails';

import MatchedTalentsTable from '../../components/Table/MatchedTalentsTable';
import PageIntro from '../../components/PageIntro/PageIntro';
import Button from '../../components/Button/index';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const MatchedTalents = () => {
  const {id} = useParams()
  // const {data} = useGetAllMatchedTalentPerJobReqQuery(id) 
  // console.log(data)
  return (
    <div>
      <PageIntro title={'Matched Talents'} />
      {/* Client Details */}
      <div className='mt-7'>
        {/* <section id='client-details'>
          <div>
            <div className='flex flex-col items-start lg:flex-row lg:flex-wrap  gap-10 lg:gap-20'>
              <div className='flex gap-[18px] items-center'>
                <div className='w-11 h-11'>
                  <img
                    alt='avatar'
                    src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
                  />
                </div>
                <span className={`text-[#001935] lg:text-lg`}>
                  Lopez Lawrence
                </span>
              </div>
              <div>
                <div className='flex gap-3 items-center'>
                  <h3 className='text-xl font-semibold'>Location</h3>
                  <MdLocationOn width={14} height={20} color='#E27D03' />
                </div>
                <p className='lg:text-lg'>London, United Kingdom</p>
              </div>
              <div>
                <div className='flex gap-3 items-center'>
                  <h3 className='text-xl font-semibold'>Request status</h3>
                </div>
                <p className='lg:text-lg'>Open</p>
              </div>
            </div>
          </div>
        </section> */}
        <div className='mt-6 mb-9 flex justify-end '>
          <Button>Rematch</Button>
        </div>
      </div>
      <div>
        <MatchedTalentsTable matched={id} />
        <div>
        
        </div>
      </div>
    </div>
  );
};

export default MatchedTalents;
