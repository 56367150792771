import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN } from '../../helper/constant';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: headers => {
      headers.set(
        'Authorization', `Bearer ${localStorage.getItem(TOKEN)}`,
        'Access-Control-Allow-Origin', "*",
        'Accept', '*/*',
        // 'Access-Control-Allow-Header', "X-Requested-With",
        // 'mode', 'no-cors',
      );
      headers.set('Accept', 'application/json');
      headers.set('Cache-Control', 'no-cache');
      headers.set('Pragma', 'no-cache');
      headers.set('Expires', '0');
      // headers.set('mode', 'cors');
      return headers
    }
  }),

  tagTypes: ['Category', 'Author', 'Post'],

  endpoints: builder => ({

    getCategory: builder.query({
      query: () => 'api/v1/admin/getBlogCats',
      transformResponse: (response) => response.data,
      providesTags: ['Category']
    }),

    addNewCategory: builder.mutation({
      query: initialPost => ({
        url: 'api/v1/admin/createBlogCat',
        body: initialPost,
        method: 'POST',
        // Include the entire post object as the body of the request
      }),
      invalidatesTags: ['Category']
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `api/v1/admin/deleteBlogCat/${id}`,
        method: 'DELETE',
        responseHandler: (response) => response.text()
      }),
      invalidatesTags: ['Category']
    }),


    getAuthor: builder.query({
      query: () => 'api/v1/admin/getBlogAuthors',
      transformResponse: (response) => response.data,
      providesTags: ['Author']
    }),

    addNewAuthor: builder.mutation({
      query: initialName => ({
        url: 'api/v1/admin/createBlogAuthor',
        method: 'POST',
        body: initialName,
        headers: {
          "Content-Type": "application/json"
        },
        // Include the entire post object as the body of the request
      }),
      // transformResponse : (response, meta, arg ) => response.data,
      invalidatesTags: ['Author'],
    }),

    deleteAuthor: builder.mutation({
      query: (id) => ({
        url: `api/v1/admin/deleteBlogAuthor/${id}`,
        method: 'DELETE',
        // responseHandler: (response) => response.text()
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'Author', id: arg.id }],
      invalidatesTags: ['Author'],
    }),

    blogPost: builder.mutation({
      query: initialBlog => ({
        url: 'api/v1/admin/createBlogPost',
        method: 'PUT',
        body: initialBlog,
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
        },
        // Include the entire post object as the body of the request
      }),
      // transformResponse : (response, meta, arg ) => response.data,
      invalidatesTags: ['Post'],
    }),

    editPost: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `api/v1/admin/createBlogPost/${id}`,
        method: 'PATCH',
        body: rest,
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
        },
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
      invalidatesTags: ['Post'],
    }),

    getBlogPost: builder.query({
      query: () => 'api/v1/admin/getBlogPosts',
      transformResponse: (response) => response.data.data,
      // providesTags: (result = [], error, arg) => [
      //   'Post',
      //   ...result.map(({ id }) => ({ type: 'Post', id }))
      // ]
      providesTags: ['Post'],
    }),

    getPost: builder.query({
      query: postId => `/api/v1/admin/getBlogPosts/${postId}`,
      transformResponse: (response) => response.data.data,
      // providesTags: (result, error, id) => [{ type: 'Post', id }],
      providesTags: ['Post'],
    }),

    deleteBlogPost: builder.mutation({
      query: (postId) => ({
        url: `api/v1/admin/deleteBlogPost/${postId}`,
        method: 'DELETE',
        // responseHandler: (response) => response.text()
      }),
      // invalidatesTags: (result, error, arg) => [{ type: 'Post', id: arg.id }],
      // invalidatesTags: (result, error, id) => [{ type: 'Post', id }],
      invalidatesTags: ['Post'],
    }),



    verifySoftSkillEmail: builder.mutation({
      query: (email) => ({
        url: `api/v1/admin/verifySoftSkillEmail`,
        method: 'PUT',
        body: email,
      }),
      transformResponse: (response) => response.data
    }),


    getPlatformKpi: builder.query({
      query: () => 'api/v1/admin/getPlatformKPI',
      transformResponse: (response) => response.data,
      providesTags: ['PlatformKpi'],
    }),

    addDevsSoftSkillResult: builder.mutation({
      query: (data) => ({
        url: 'api/v1/admin/addDevsSoftSkillResult',
        method: 'POST',
        body: data,
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Headers': 'origin,X-Requested-With,content-type,accept',
        },
      }),
    }),


    getAllHireRequest: builder.query({
      query: (page) => `api/v1/admin/getAllHireRequest?page=${page + 1}`,
      transformResponse: (response) => response.data,
      providesTags: ['HireRequest'],
    }),

    getAHireRequestById: builder.query({
      query: (reqId) => `api/v1/admin/getAHireRequestById/${reqId}`,
      transformResponse: (response) => response.data,
      providesTags: ['HireRequest'],
    }),


    getAllMatchedTalentPerJobReq: builder.query({
      query: (reqId, page) => `/api/v1/admin/getAllMatchedTalentPerJobReq/${reqId}/is_invited?page=${page + 1}`,
      transformResponse: (response) => response.data,
      // providesTags: (result, error, id) => [{ type: 'Post', id }],
      providesTags: ['Post'],
    }),

    getACbtDetail: builder.query({
      query: (id) => `/api/v1/admin/getATalentsCbtDetails/${id}`,
      transformResponse: (response) => response.data,
      // providesTags: (result, error, id) => [{ type: 'Post', id }],
      providesTags: ['Post'],
    }),
  })

})

export const {
  useGetCategoryQuery,
  useAddNewCategoryMutation,
  useBlogPostMutation,
  useDeleteCategoryMutation,
  useGetAuthorQuery,
  useAddNewAuthorMutation,
  useDeleteAuthorMutation,
  useGetBlogPostQuery,
  useGetPostQuery,
  useDeleteBlogPostMutation,
  useEditPostMutation,
  useVerifySoftSkillEmailMutation,
  useAddDevsSoftSkillResultMutation,
  useGetPlatformKpiQuery,
  useGetAllHireRequestQuery,
  useGetAHireRequestByIdQuery,
  useGetAllMatchedTalentPerJobReqQuery,
  useGetACbtDetailQuery,
} = apiSlice




