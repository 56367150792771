import React from 'react';
import CreateCatAut from '../components/FirstSection/createCatAut';

const SelectCatAut = () => {
  return (
    <div>
        <CreateCatAut />
    </div>
  )
}

export default SelectCatAut