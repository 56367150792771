import React,{ useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAddNewAuthorMutation } from '../../reactWrapper/Redux/apiSlice';


const CreateAuthors = () => {
    
    // const [addNewAuthor, data, { isLoading }] = useAddNewAuthorMutation();
    const [addNewAuthor, data] = useAddNewAuthorMutation();
    console.log('author data', data)

    const unique_id = uuid();
    const uid = unique_id.slice(0,4);

    const [name, setName] = useState('');
    const [userId, setUserId] = useState(uid);


    const handleSubmit = async (e) => {
        e.preventDefault()
        await addNewAuthor({ name,  userD: userId })
        setName('')         
    }


  return (
    <>
      <div className="font-medium text-base text-black block mb-3">
          Create Author
      </div>

      <div className="w-full px-4">
          <form onSubmit={handleSubmit} className="flex flex-row mb-8" >
              <div className="basis-3/4">
                  
                  <input type="text" placeholder="Active Input" 
                  value={name}
                  onChange={(e)=> setName(e.target.value)}
                  className="
                      w-full
                      border-[1.5px] border-primary
                      rounded-lg
                      py-3
                      px-5
                      font-medium
                      text-body-color
                      placeholder-body-color
                      outline-none
                      focus:border-primary
                      active:border-primary
                      transition
                      disabled:bg-[#F5F7FD] disabled:cursor-default
                      " />
              </div>

              <div className="basis-1/4 self-center ml-3.5">
                <button onClick={handleSubmit} className="place-self-center shadow-lg shadow-blue-500/50 bg-blue-800 hover:bg-blue-900 p-2.5 rounded-lg text-white font-bold" > 
                  Submit 
                </button>
              </div>
          </form>
      </div>

    </>
  )
}

export default CreateAuthors