import React from 'react';

const PageIntro = ({ title, description }) => {
  return (
    <div>
      <h1 className='text-xl lg:text-3xl mb-5 font-bold text-[#001935]'>
        {title}
      </h1>
      <p className='text-[#575555]'>{description}</p>
    </div>
  );
};

export default PageIntro;
