import React from 'react'
import BlogDetails from '../components/BlogPost/blogDetail';
import { useGetPostQuery } from '../reactWrapper/Redux/apiSlice';


export const SingleBlog =({ match })=>{
    const { postId } = match.params

    const { data: post, isFetching, isSuccess } = useGetPostQuery(postId)
    console.log('this is the detail', post)


        if (isFetching) {
            return(
                <div> loading.... </div>
            )
            
        } else if (isSuccess) {
            return(
                <div>
                    <BlogDetails post={post} />
                </div>
            )
        }
    
}