import React from 'react'

export const Skills = ({skill}) => {
  return (
    
    <div className=" relative col-span-1 mt-6 p-6 bg-white shadow-lg rounded-lg ">  
  
    <div className="mt-3">
      <h3 className="text-xl font-semibold">Skill</h3>
     
  
      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill1_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill1_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill1_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill1_point}</span>
        </div>


      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill2_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill2_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill2_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill2_point}</span>
        </div>


      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill3_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill3_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill3_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill3_point}</span>
        </div>


      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill4_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill4_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill4_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill4_point}</span>
        </div>


      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill5_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill5_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill5_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill5_point}</span>
        </div>


      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">{skill?.skill6_name}</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.skill6_percent_score}%` }}
            ></div>
         </div>
          <span className='pr-6'>{`${skill?.skill6_percent_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.skill6_point}</span>
        </div>
  

  
    </div>
  
    <div className="mt-12">
    <h3 className="text-xl font-semibold">Others</h3>
      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">Internet</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.internet_score}%` }}
            ></div>
         </div>
         <span className='pr-6'>{`${skill?.internet_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.internet_point}</span>
        </div>
  
  
      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">Security</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.security_score}%` }}
            ></div>
         </div>
         <span className='pr-6'>{`${skill?.security_score}`}%</span>
          <span className=" p-6 text-gray-600">{skill?.security_point}</span>
        </div>
  
  
      <div  className="flex items-center p-0">
          <span className="text-gray-600 w-24">Version Control</span>
          <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
            <div
              className="h-full bg-blue-500"
              style={{ width: `${skill?.version_control_score}%` }}
            ></div>
         </div>
         <span className='pr-6'>{`${skill?.version_control_score}`}%</span>
         <span className=" p-6 text-gray-600">{skill?.version_control_point}</span>
        </div> 
      </div>
  
      
       </div>
  )
}
