import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./userProfileSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import blogReducer from './blogSlice'
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { apiSlice } from "./apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import auth from "./auth/authSlice";

// const reducers = combineReducers({
//   profile: profileReducer,
//   // blog: blogReducer,
//   [apiSlice.reducerPath]: apiSlice.reducer,
// })

// const persistConfig = {
//   key: 'root',
//   storage,
//   // blackList: ['blog']
// };

// const persistedReducer = persistReducer(persistConfig, reducers);
// export const store = configureStore({
//   reducer: persistedReducer,
//   // devTools: process.env.REACT_APP_ENV  !== 'production',
//   // // middleware: [thunk],
//   // middleware: getDefaultMiddleware =>
//   // getDefaultMiddleware({
//   //   serializableCheck: {
//   //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
//   //   }
//   // }).concat([
//   //   apiSlice.middleware
//   // ]),
//     middleware: getDefaultMiddleware =>
//     getDefaultMiddleware().concat(apiSlice.middleware)
// });

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    auth,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// setupListeners(store.dispatch)

// export const store = configureStore({
//   reducer:{
//     profile: profileReducer,
//     blog: blogReducer,
//   }
// })

// export const store = configureStore({
//   reducer: {
//     profile: profileReducer,
//     blog: blogReducer,
//     [apiSlice.reducerPath]: apiSlice.reducer
//   },
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware().concat(apiSlice.middleware)
// })
