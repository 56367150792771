import { useReducer, createContext, useContext } from 'react';

const PreloaderContext = createContext()
const PreloaderDispatchContext = createContext()
const AddTeamContext = createContext()
const AddTeamDispatchContext = createContext()

let preloaderState = {preloaderdisplay:true}
const reducerPreloader = (state,action)=>{
        switch(action.type){
        case 'PRELOADER_DISPLAY':
        return preloaderState = {...preloaderState,preloaderdisplay:action.payload}
        default:
        throw new Error(`Unknown action: ${action.type}`)
        }
    }


let addedTeamMemberState = {addedTeamMemberId:{}}
const reducerAddedTeamMember = (state,action)=>{
    switch(action.type){
    case 'ADDED_TEAM_MEMBER':
    return addedTeamMemberState = {...addedTeamMemberState,addedTeamMemberId:action.payload}
    default:
    throw new Error(`Unknown action: ${action.type}`)
    }
}


//Set context info
export function ContextProvider({children}) {
const [loaderState,dispatchPreloader] = useReducer(reducerPreloader,preloaderState)
const [addTeamState,dispatchAddTeam] = useReducer(reducerAddedTeamMember,addedTeamMemberState)
  

    return (
         
                <PreloaderDispatchContext.Provider value={dispatchPreloader}>
                <PreloaderContext.Provider value={loaderState}>
                <AddTeamDispatchContext.Provider value={dispatchAddTeam}>
                <AddTeamContext.Provider value={addTeamState}>
                     {children}
                </AddTeamContext.Provider>
                </AddTeamDispatchContext.Provider>
                </PreloaderContext.Provider>
                </PreloaderDispatchContext.Provider>
              
    );

}

//Get the context info 

export const usePreloaderContext = ()=> useContext(PreloaderContext)
export const usePreloaderDispatchContext = ()=>useContext(PreloaderDispatchContext)
export const useAddTeamContext = ()=> useContext(AddTeamContext)
export const useAddTeamDispatchContext = ()=>useContext(AddTeamDispatchContext)

