import * as Yup from 'yup';

export const initialSearchValue = {
  email: '',
};

export const SearchTalentSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .trim()
    .required('Email is required'),
});



export const TalentScoreSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .trim()
    .required('Email is required'),
  communication: Yup.number()
    .required('Enter score for communication')
    .min(0, 'Communication score must be at least 0')
    .max(100, 'Communication score must be at most 100'),
  motivation: Yup.number()
    .required('Enter score for motivation')
    .min(0, 'Motivation score must be at least 0')
    .max(100, 'Motivation score must be at most 100'),
  consistency: Yup.number()
    .required('Enter score for consistency')
    .min(0, 'Consistency score must be at least 0')
    .max(100, 'Consistency score must be at most 100'),
  leadership: Yup.number()
    .required('Enter score for leadership')
    .min(0, 'Leadership score must be at least 0')
    .max(100, 'Leadership score must be at most 100'),
  creativity: Yup.number()
    .required('Enter score for creativity')
    .min(0, 'Creativity score must be at least 0')
    .max(100, 'Creativity score must be at most 100'),
  productivity: Yup.number()
    .required('Enter score for productivity')
    .min(0, 'Productivity score must be at least 0')
    .max(100, 'Productivity score must be at most 100'),
  depth: Yup.number()
    .required('Enter score for depth')
    .min(0, 'Depth score must be at least 0')
    .max(100, 'Depth score must be at most 100'),
  velocity: Yup.number()
    .required('Enter score for velocity')
    .min(0, 'Velocity score must be at least 0')
    .max(100, 'Velocity score must be at most 100'),
  alignment: Yup.number()
    .required('Enter score for value alignment')
    .min(0, 'Alignment score must be at least 0')
    .max(100, 'Alignment score must be at most 100'),
  self: Yup.number()
    .required('Enter score for self driven')
    .min(0, 'Self driven score must be at least 0')
    .max(100, 'Self driven score must be at most 100'),
});
