import React from 'react';
import { useDeleteBlogPostMutation } from '../../reactWrapper/Redux/apiSlice'


const Modal =({ show, onClose, deleteId}) => {


    const [ deleteBlogPost, {isLoading: catLoading } ] = useDeleteBlogPostMutation()

    if(!show){
        return null;
    }

    return(
        <>
            <section>
                <div className="container py-20">
                    <button
                        className="
                        py-3
                        px-6
                        rounded-full
                        bg-primary
                        text-white
                        font-medium
                        text-base
                        "
                        >
                    Open Modal
                    </button>
                </div>
                <div
                    className="
                    bg-black bg-opacity-90
                    fixed
                    top-0
                    left-0
                    w-full
                    min-h-screen
                    h-full
                    flex
                    items-center
                    justify-center
                    px-4
                    py-5
                    "
                    >
                    <div
                        className="
                        w-full
                        max-w-[570px]
                        rounded-[20px]
                        bg-white
                        py-12
                        px-8
                        md:py-[60px] md:px-[70px]
                        text-center
                        "
                        >
                        <h3 className="font-bold text-dark text-xl sm:text-2xl pb-2">
                            Delete Post {deleteId}
                        </h3>
                        <span
                            className="inline-block bg-primary h-1 w-[90px] mx-auto rounded mb-6"
                            ></span>
                        <p className="text-base text-body-color leading-relaxed mb-10">
                            Are you sure you want to delete this post. Then if you are sure, go ahead. But if not, click on cancel to go back thanks.
                        </p>
                        <div className="flex flex-wrap -mx-3">
                            <div className="w-1/2 px-3">
                            <button
                                onClick={onClose}
                                className="
                                block
                                text-center
                                w-full
                                p-3
                                text-base
                                font-medium
                                rounded-lg
                                text-dark
                                border border-[#E9EDF9]
                                hover:bg-red-600 hover:text-white hover:border-red-600
                                transition
                                "
                                >
                            Cancel
                            </button>
                            </div>
                            <div className="w-1/2 px-3">
                                <button
                                    onClick={()=>
                                        deleteBlogPost(deleteId ) && onClose()           
                                    }
                                    className="
                                    block
                                    text-center
                                    w-full
                                    p-3
                                    text-base
                                    font-medium
                                    rounded-lg
                                    bg-primary
                                    text-dark
                                    border border-primary
                                    hover:bg-opacity-90
                                    transition
                                    "
                                    >
                                Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Modal;