import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.tsx';
import { ContextProvider } from './reactWrapper/Context/AppContext.js'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import reportWebVitals from './reportWebVitals.js';
import { SWRConfig } from "swr";
import { store } from './reactWrapper/Redux/store';

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            // console.log('global_error', error)
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
      }}
    >
      <ContextProvider>
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <App />
          {/* </PersistGate> */}
        </Provider>
      </ContextProvider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
