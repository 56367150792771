import React from 'react'

export const SoftSkill = ({hr}) => {
  return (
     <div className="grid p-0  mt-4">
        

    <div className="col-span-1 flex flex-col mt-0 bg-white shadow-md rounded-lg p-4">
        <div className="font-bold text-l mb-2 text-gray-400"> HR RESULT</div>
        <div className="flex flex-col space-y-2">
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Communication</span>
               <span> {hr?.communication} </span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Motivation</span>
                <span> {hr?.motivation}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Consistency</span>
                <span> {hr?.consistency_reliability}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Leadership</span>
                <span> {hr?.leadership}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Creativity</span>
                <span> {hr?.creativity}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Productivity</span>
                <span> {hr?.productivity}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Depth Of Past Work</span>
                <span> {hr?.depth_of_past_work}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Learning Velocity</span>
                <span> {hr?.learning_velocity}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Value Alignment</span>
                <span> {hr?.value_alignment}</span>
            </div>
            <div className="flex justify-between items-center border-b border-gray-300 py-2">
                <span>Self Driven</span>
                <span> {hr?.self_driven}</span>
            </div>
            
        </div>
    </div>


  </div>
  )
}
