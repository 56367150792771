import React from 'react'

export const Language = ({lang}) => {
  return (
    
         <div className='  relative col-span-2 mt-6 p-6 bg-white shadow-lg rounded-lg '>
         {/* Language SEction */}
         <div className="mt-3">
        <h3 className="text-xl font-semibold">Language</h3>
  
          <div  className="flex items-center mt-2">
            <span className="text-gray-600 w-24">{lang?.language1_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.language1_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language1_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language1_point}</span>
          </div>



          <div  className="flex items-center p-0 ">
            <span className="text-gray-600 w-24">{lang?.language2_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className ="h-full bg-blue-500"
                style={{ width: `${lang?.language2_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language2_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language2_point}</span>
          </div>



          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.language3_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.language3_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language3_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language3_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.language4_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.language4_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language4_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language4_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.language5_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.language5_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language5_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language5_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.language6_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.language6_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.language6_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.language6_point}</span>
          </div>
    
    
      </div>

              {/* Framework */}

              <div className="mt-12">
        <h3 className="text-xl font-semibold">Framework</h3>
       

        <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework1_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework1_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework1_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework1_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework2_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework2_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework2_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework2_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework3_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework3_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework3_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework3_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework4_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework4_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework4_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework4_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework5_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework5_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework5_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework5_point}</span>
          </div>

          <div  className="flex items-center p-0">
            <span className="text-gray-600 w-24">{lang?.framework6_name}</span>
            <div className="flex-1 h-4 bg-gray-200 rounded-lg overflow-hidden mx-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${lang?.framework6_percent_score}%` }}
              ></div>
           </div>
            <span className='pr-6'>{`${lang?.framework6_percent_score}`}%</span>
            <span className=" p-6 text-gray-600">{lang?.framework6_point}</span>
          </div>


 
      </div>

        </div>
    
  )
}
