import React, {useState} from 'react';
import Modal from './modalPost'
import { Link } from 'react-router-dom'

const BlogCard = ({blog}) => {
    const [show, setShow] = useState(false);

    const modalClick=(e) =>{
        e.preventDefault()
        setShow(!show)
    }   

  return (
    <div className="max-w-2xl px-8 py-4 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 mb-4" key={blog.id}>
        <div className="flex items-center justify-between">
            <span className="text-sm font-light text-gray-600 dark:text-gray-400">{blog.created_at}</span>
            <button className="px-3 py-1 text-sm font-bold text-gray-100 transition-colors duration-200 transform bg-gray-600 rounded cursor-pointer hover:bg-gray-500"
            onClick={modalClick} 
            // onClick={()=>
            //     dispatch(deleteBlog( blog.id ))              
            // }
            >
                Delete Post {blog.id}
            </button>
        </div>
        <Modal onClose={() => setShow(false)} show={show} deleteId={blog.id} />

        <div className="mt-2">
            <a href="./../pages/Blog" className="text-2xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline">{blog.title}</a>
            <p className="mt-2 text-gray-600 dark:text-gray-300">{blog.meta_description}</p>
        </div>
        
        <div className="flex items-center justify-between mt-4">
            <Link to ={`/mypost/${blog.id}`} >
                <p className="text-blue-600 dark:text-blue-400 hover:underline">
                    Read more
                </p>
            </Link>

            <div className="flex items-center">
                <img className="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block" src={blog.featured_image_link} alt={blog.title} />
                <a href="./../pages/Blog" className="font-bold text-gray-700 cursor-pointer dark:text-gray-200">{blog.authors_name}</a>
            </div>
        </div>
    </div>
  )
}

export default BlogCard
